.react-select-container {
  @apply border-0;

  [class$='-control'] {
    @apply pl-3 pr-0 h-12 w-full flex items-center text-base text-galaxy-800 rounded-lg font-semibold bg-white focus-within:bg-white border-2 border-solid border-galaxy-200 focus-within:border-meteor outline-0 focus-visible:outline-0 focus-visible:border-meteor focus-visible:bg-white ring-0 hover:border-meteor hover:bg-white transition-colors duration-200 ease-in-out;
  }
  [class$='__is-focused'] {
    @apply border-2 border-solid border-meteor;
  }
  [class$='-indicatorSeparator'] {
    display: none;
  }
  [class$='-menu'] {
    @apply h-40 p-4 text-base text-galaxy-800 overflow-hidden;
  }
  [class$='-option'] {
    @apply rounded-lg cursor-pointer;
  }
}
